/* General Styles */
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  background: linear-gradient(to bottom right, rgba(247, 236, 225, 1), rgba(247, 236, 225, 0.9)), #F7ECE1; /* Static gradient background */
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  color: #fff; /* Default text color for social icons */
  /* Removed background animation */
}

/* Header Styles */
.App-header {
  max-width: 800px; /* Adjust max-width to fit larger logo */
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fadeIn 1s ease-in-out, slideDown 1s ease-out; /* Fade in and slide down effect */
}

/* Logo */
.logo {
  max-width: 400px; /* Set logo size to 400px */
  width: 100%; /* Ensure responsiveness */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 10px; /* Reduce space below the logo */
  animation: logoBounce 2s ease-in-out infinite; /* Bounce animation for logo */
}

/* Website Name */
h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 3em; /* Adjusted font size for emphasis */
  margin: 0;
  font-weight: 700;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3); /* Enhanced text shadow */
  color: #032B43; /* Static text color */
  margin-bottom: 15px; /* Space between heading and paragraph */
  /* Removed animation for color transition */
  letter-spacing: 2px; /* Added letter spacing for a refined look */
  line-height: 1.2; /* Adjusted line height for better readability */
}

/* Coming Soon Heading */
h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 2em;
  margin: 0;
  font-weight: 600;
  color: #032B43; /* Subheading text color */
  margin-bottom: 15px; /* Space between heading and paragraph */
  animation: textPop 1.5s ease-out; /* Pop-in effect for subheading */
}

/* Paragraph */
p {
  margin: 10px 0; /* Reduced margin to create balance */
  font-size: 1.2em;
  font-weight: 300;
  line-height: 1.5;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  color: #032B43; /* Paragraph text color */
  animation: textFadeIn 2s ease-in; /* Fade-in animation for paragraph */
}

/* Social Icons */
.social-icons {
  margin-top: 20px; /* Reduced margin for better balance */
  display: flex;
  justify-content: center;
  gap: 20px; /* Space between icons */
  animation: socialFadeIn 3s ease-in-out; /* Fade-in animation for social icons */
}

.social-icons a {
  color: #032B43; /* Default social icons color */
  font-size: 1.8em; /* Adjust icon size */
  transition: color 0.5s ease, transform 0.3s ease; /* Smooth transition */
}

.social-icons a:hover {
  color: #F26419; /* Hover color */
  transform: scale(1.2); /* Grow on hover */
}

/* Specific styling for eBay icon */
.ebay-icon {
  font-size: 2em; /* Increase size of eBay icon */
}

/* Keyframes for Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes slideDown {
  from { transform: translateY(-30px); }
  to { transform: translateY(0); }
}

@keyframes logoBounce {
  0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
  40% { transform: translateY(-10px); }
  60% { transform: translateY(-5px); }
}

@keyframes textPop {
  0% { transform: scale(0); opacity: 0; }
  100% { transform: scale(1); opacity: 1; }
}

@keyframes textFadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes socialFadeIn {
  from { opacity: 0; transform: translateY(30px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Removed backgroundFade keyframes */

/* Responsive Styles */
@media (max-width: 600px) {
  .App-header {
    padding: 10px;
  }

  .logo {
    max-width: 300px; /* Adjust logo size for smaller screens */
    margin-bottom: 10px; /* Adjust spacing for smaller screens */
  }

  h1 {
    font-size: 2em;
    margin-bottom: 10px; /* Reduced space for smaller screens */
  }

  h2 {
    font-size: 1.5em; /* Adjust font size for smaller screens */
    margin-bottom: 10px; /* Reduced space for smaller screens */
  }

  p {
    font-size: 1em;
    margin: 5px 0; /* Reduced margin for smaller screens */
  }

  .social-icons a {
    font-size: 1.5em; /* Adjust icon size for smaller screens */
  }
}
